
jQuery('[data-module]').each(function(){
	let $element = jQuery(this);
	let name = $element.attr('data-module');

	import(
		/* webpackChunkName: "[request]" */
		'../modules/' + name
	).then(({ default: Module }) => {
		new Module($element);
	});
});


// Bootstrap
// https://www.npmjs.com/package/bootstrap-sass
// import 'bootstrap/js/src/alert';
// import 'bootstrap/js/src/button';
// import 'bootstrap/js/src/carousel';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/modal';
// import 'bootstrap/js/src/popover';
// import 'bootstrap/js/src/scrollspy';
// import 'bootstrap/js/src/toast';
// import 'bootstrap/js/src/tooltip';


// svg4everybody
// https://www.npmjs.com/package/svg4everybody
import svg4everybody from 'svg4everybody';
svg4everybody({ nosvg: false });
